export const seo = {
  url: 'wycena-transportu',
  title: {
    pl: 'Wycena | Transport międzynarodowy i krajowy',
    en: 'Quote | International and domestic transport',
  },
  desc: {
    pl: 'Złóż zapytanie na wycenę transportu międzynarodowego i transportu krajowego. Zyskaj doświadczonego partnera logistycznego. Pracuj z polską spedycją.',
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Wycena Transportu',
    en: 'Transport Quote',
  },
  desc: {
    pl: 'Złóż zapytanie na wycenę transportu międzynarodowego i transportu krajowego. Zyskaj doświadczonego partnera logistycznego. Pracuj z polską spedycją.',
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
  },
}

export const main = {
  title: {
    pl: 'Szukasz transportu? Złóż darmowe zapytanie wyceny usługi transportu.',
    en: 'Llooking for transport? Make a free quotation request for the transport service.',
  },
  texts: [
    {
      pl: '<span>Wybierz doświadczoną firmę transportową. Zrealizowaliśmy ponad <strong>2 mln transportów</strong> w Europie i na świecie. Określ miejsce i datę załadunku oraz rozładunku Twojego towaru. Opcjonalnie podaj parametry poszczególnych ładunków. W wiadomości możesz opisać założenia i potrzeby zlecenia. Nasi spedytorzy skontaktują się z Tobą mailowo lub telefonicznie <strong>najszybciej, jak to możliwe</strong>.</span>',
      en: '<span>Choose an experienced forwarding company. We have completed over <strong>2 million shipments</strong> in Europe and around the world. Specify the place and date of loading and unloading your goods. Optionally, enter the parameters of individual loads. In the message, you can describe the assumptions and needs of the order. Our forwarders will contact you by e-mail or phone <strong> as soon as possible </strong>.</span>',
    },
  ],
}
