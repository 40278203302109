import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/wycena-transportu'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import { useFormContext } from '../context/form.context'
import FormDynamic from '../components/FormDynamic'
import { SectionBackground } from '../components/Section'
import QuoteAdditionalText from '../sections/wycena-transportu/QuoteAdditionalText'

const WycenaTransportu = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/wycena-transportu.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: DOMINANT_COLOR
            formats: [WEBP, AVIF]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Złóż zapytanie',
      en: 'Query now',
    },
    action: () => setFormOpen(true),
  }

  const buttonSecond = {
    text: {
      pl: 'Jak to działa?',
      en: 'How it works?',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/wycena-transportu.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />
      <SectionBackground mobile top pbXL>
        <FormDynamic notypes override='QUOTE' />
      </SectionBackground>
      <div css={sAdditionalText}>
        <QuoteAdditionalText />
      </div>
    </Layout>
  )
}

const sAdditionalText = {
  padding: '0 0 3rem'
}

export default WycenaTransportu
